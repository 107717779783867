import { getIn } from "formik"
import SiteLanguage from "../../../helpers/siteLanguage"

const handleValidation = values => {
  let errors = {}

  if (!values.name) {
    errors.name = SiteLanguage() === "en" ? "This field is required" : "To pole jest wymagane"
  }

  if (!values.email) {
    errors.email = SiteLanguage() === "en" ? "This field is required" : "To pole jest wymagane"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = SiteLanguage() === "en" ? "There is something wrong with given e-mail address" : "Podany e-mail ma niepoprawny format"
  }

  if (!values.message) {
    errors.message = SiteLanguage() === "en" ? "This field is required" : "To pole jest wymagane"
  }

  if (!values.acceptance) {
    errors.acceptance = SiteLanguage() === "en" ? "This field is required" : "To pole jest wymagane"
  }

  return errors
}

const highlightFieldIfError = (error, fieldName, touched) => {
  if (getIn(error, fieldName) && touched)
    return {
      "--input-theme": "var(--red)",
      "--input-transparent-theme": "var(--red-transparent-min)",
    }
}

export { handleValidation, highlightFieldIfError }
