import React, { useState } from "react"
import { Formik, Form } from "formik"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { handleValidation } from "./form/helpers"

import { SecondaryTitle } from "../global/common/text"
import { Button } from "../global/atoms/Button"

import { TextField } from "./form/TextField"
import { TextArea } from "./form/TextArea"
import { Checkbox } from "./form/Checkbox"
import { SuccessAlert } from "./form/SuccessAlert"

import SiteLanguage from "../../helpers/siteLanguage"

const ACFQuery = graphql`
  {
    wp {
      formLabels {
        en {
          contactFormButton
          contactFormCheckbox
          contactFormEmail
          contactFormMessage
          contactFormName
          contactFormPhone
        }
        pl {
          contactFormButtonPl
          contactFormCheckboxPl
          contactFormEmailPl
          contactFormMessagePl
          contactFormNamePl
          contactFormPhonePl
        }
      }
    }
  }
`

const FormHeader = styled(SecondaryTitle)`
  width: min(100%, 370px);
`

const ContactForm = ({ containerStyle, data }) => {
  const [isFormSubmitted, formWasSubmitted] = useState(false)

  const acfData = useStaticQuery(ACFQuery)
  const enData = acfData.wp.formLabels.en
  const plData = acfData.wp.formLabels.pl

  let header,
    nameLabel,
    emailLabel,
    phoneLabel,
    messageLabel,
    checkboxLabel,
    buttonLabel

  if (SiteLanguage() === "en") {
    header = enData.contactFormHeader
    nameLabel = enData.contactFormName
    emailLabel = enData.contactFormEmail
    phoneLabel = enData.contactFormPhone
    messageLabel = enData.contactFormMessage
    checkboxLabel = enData.contactFormCheckbox
    buttonLabel = enData.contactFormButton
  } else {
    header = plData.contactFormHeaderPl
    nameLabel = plData.contactFormNamePl
    emailLabel = plData.contactFormEmailPl
    phoneLabel = plData.contactFormPhonePl
    messageLabel = plData.contactFormMessagePl
    checkboxLabel = plData.contactFormCheckboxPl
    buttonLabel = plData.contactFormButtonPl
  }

  return (
    <section style={containerStyle} id="form">
      {header && (
        <FormHeader
          dangerouslySetInnerHTML={{ __html: header }}
          style={{ "--spacing-bottom": "70px" }}
        ></FormHeader>
      )}
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          message: "",
          acceptance: null,
        }}
        validate={handleValidation}
        onSubmit={(values, { resetForm }) => {
          fetch(`${process.env.GATSBY_WP_URL}send-message`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(values),
          })
            .then(response => response.json())
            .then(data => {
              formWasSubmitted(
                "Message sent! We will contact you as fast as possible."
              )
              resetForm()
            })
            .catch(error =>
              formWasSubmitted(
                "There is something wrong with our server. May you call Andrzej or Michał? You can find them next to the form."
              )
            )
        }}
        validateOnBlur
      >
        {({ touched, errors, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label={nameLabel}
              touched={touched.name}
              error={errors.name}
            />
            <TextField
              name="email"
              label={emailLabel}
              type="email"
              touched={touched.email}
              error={errors.email}
            />
            <TextField name="phone" label={phoneLabel} type="text" />
            <TextArea
              name="message"
              label={messageLabel}
              touched={touched.message}
              error={errors.message}
            />
            <Checkbox
              name="acceptance"
              label={checkboxLabel}
              touched={touched.acceptance}
              allErrors={errors}
            />
            <Button theme="green" type="submit" as="button">
              {buttonLabel}
            </Button>
            <SuccessAlert isFormSubmitted={isFormSubmitted} />
          </Form>
        )}
      </Formik>
    </section>
  )
}

export { ContactForm }
