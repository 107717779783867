import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Seo from "gatsby-plugin-wpgraphql-seo"

import { maxD } from "../../assets/styles/helpers/devices"

import Layout from "../../assets/styles/layout"
import { dotsPseudoelement } from "../../components/global/common/ornaments"

import { LineWrapper } from "../../components/global/common/containers"
import { FooterContainer } from "../../components/global/common/containers"
import Footer from "../../components/global/Footer"

import { SimpleHeader } from "../../components/global/SimpleHeader"
import { Executive } from "../../components/contact/Executive"
import { Data } from "../../components/contact/Data"
import { ContactForm } from "../../components/contact/Form"
import { Map } from "../../components/contact/Map"

import BriefInvite from "../../components/contact/BriefInvite"

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfContact {
        contactIntro
        contactIntroLink {
          target
          title
          url
        }
        contactBriefInvite
        contactBriefInviteLink {
          target
          title
          url
        }
        contactDetails
        contactDetailsHeader
        contactFormHeader
        contactMap {
          latitude
          longitude
        }
        contactOfficeData {
          contactOfficeIcon
          contactOfficeDescription
          contactOfficeSubHeader
          contactOfficeLink {
            target
            title
            url
          }
        }
        contactOfficeHeader
        contactPeople {
          email
          personName
          personImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          phone
          personPosition
        }
      }
    }
  }
`

const ContactMainContent = styled(LineWrapper)`
  padding-top: 120px;
  padding-bottom: 80px;

  @media ${maxD.laptopL} {
    padding-top: 80px;
    padding-bottom: 60px;
  }
`

const ContactMainInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  &::after {
    ${dotsPseudoelement}

    width: 1050px;
    height: 950px;

    top: 180px;
    right: 0;
  }
`

const ContactMainColumn = styled.div`
  width: calc(50% - 50px);

  @media ${maxD.desktop} {
    width: calc(50% - 25px);
  }

  @media ${maxD.laptop} {
    width: 100%;
  }
`

const ContactPage = ({ pageContext, data: { wpPage } }) => {
  const { seo } = wpPage
  const data = wpPage.acfContact

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={wpPage} />
      )}
      <SimpleHeader {...{ data }} pageContext={pageContext} />
      <ContactMainContent as="main">
        <ContactMainInner>
          <ContactMainColumn>
            <Executive {...{ data }} />
            <Data {...{ data }} />
          </ContactMainColumn>
          <ContactMainColumn>
            <BriefInvite {...{ data }} />
            <ContactForm />
          </ContactMainColumn>
        </ContactMainInner>
      </ContactMainContent>
      <Map {...{ data }} />
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default ContactPage
